<script setup>
import { Head, Link, useForm } from '@inertiajs/vue3';
import Checkbox from '@/Components/Checkbox.vue';
import InputError from '@/Components/InputError.vue';
import InputLabel from '@/Components/InputLabel.vue';
import ButtonStd from '@/Components/ButtonStd.vue';
import TextInput from '@/Components/TextInput.vue';
import Heading1 from '@/Components/Heading1.vue';
import Heading2 from '@/Components/Heading2.vue';


defineProps({
    canResetPassword: Boolean,
    status: String,
});

const form = useForm({
    email: '',
    password: '',
    remember: false,
});

const submit = () => {
    form.transform(data => ({
        ...data,
        remember: form.remember ? 'on' : '',
    })).post(route('login'), {
        onFinish: () => form.reset('password'),
    });
};
</script>

<template>
    <Head title="Log in"/>
    
    <div class="min-h-screen grid grid-cols-12 gap-4 justify-center items-center bg-gradient-to-b 
            from-zinc-50 via-stone-50 to-orange-50 
            dark:from-slate-950 dark:via-zinc-950 dark:to-blue-950">

        <div class="h-full w-full md:col-span-7 col-span-12 flex justify-center md:items-center items-end">
            <div class="w-80 md:justify-center justify-start">
                <img class=" md:w-64 md:h-64 w-14 h-14" src="/asset/media/logo/logo-s.svg" alt="Logo icon">
            </div>
        </div>

        <div class="h-full w-full md:col-span-5 col-span-12 flex justify-center md:justify-start items-start md:items-center">

            <form @submit.prevent="submit" class="w-80 flex flex-col gap-2">

                <Heading1 :class="'font-dynapuff !text-4xl'" :text="'home.welcome1'"/>
                <Heading2 :class="'mb-2 font-dynapuff'" :text="'auth.join_now'"/>

                <div class="grid gap-1 w-72">
                    <InputLabel for="email" :value="$t('auth.email')" />
                    <TextInput id="email" v-model="form.email" type="email" class="block w-full" required autofocus autocomplete="username" />
                    <InputError class="" :message="form.errors.email" />
                </div>
                <div class="grid gap-1 w-72">
                    <InputLabel for="login.password" :value="$t('auth.password')" />
                    <TextInput id="password" v-model="form.password" type="password" class="block w-full" required autocomplete="current-password" />
                    <InputError class="" :message="form.errors.password" />
                </div>
                <div class="block w-72">
                    <label class="flex items-center">
                        <Checkbox v-model:checked="form.remember" name="remember" />
                        <span class="ml-2 text-sm text-gray-600 dark:text-gray-400">{{ $t('auth.remember') }}</span>
                    </label>
                </div>
                <div class="flex items-center justify-between w-72">
                    <Link v-if="canResetPassword" :href="route('password.request')" class="link">
                        {{ $t('auth.password.forgot') }}
                    </Link>

                    <ButtonStd :active="true" class="ml-4 min-w-24" :class="{ 'opacity-25': form.processing }" :disabled="form.processing" :label="'auth.login'"/>
                </div>

                <p class="text-sm text-gray-800 dark:text-gray-200"> 
                    {{ $t('auth.login.description') }}
                    <Link :class="'text-sky-500'" :href="route('policy')"> {{ $t('auth.privacy') }}  </Link>
                    {{ $t('global.and') }} 
                    <Link :class="'text-sky-500'" :href="route('terms')"> {{ $t('auth.terms') }} </Link>
                    .
                </p>

                <Heading2 :class="'mt-2 font-dynapuff'" :text="'auth.profile.no'"/>

                <Link v-if="true /*canRegister*/" :href="route('register')" class="link">
                    {{ $t('auth.register') }}
                </Link>

                <div v-if="status" class="mb-4 font-medium text-sm text-green-600 dark:text-green-400">
                    {{ status }}
                </div>

            </form>

        </div>

    </div>
</template>
